// https://flatpickr.js.org/getting-started/

const calendarInit = () => {
    let pageLang = document.querySelector("html").getAttribute("lang");

    const calendars = document.querySelectorAll('[data-reservations-calendar]')

    if(!calendars || calendars.length === 0) return

    let calendarInstances = [];

    for (let calendar of calendars) {
        const bookDatesSlug = calendar.dataset.reservationsCalendar;

        const url = window.location.origin + (window.location.hostname == 'localhost' ? '/zooapartmany.cz' : '') + '/reservations/' + bookDatesSlug + '/all/json'

        fetch(url)
            .then(res => res.json())
            .then(out => {

                let loader = document.querySelector('[data-reservations-calendar-loader="' + bookDatesSlug +'"]')
                if(loader) {
                    loader.remove()
                }

                const calendarInstance = flatpickr(calendar, {
                    locale: pageLang,
                    mode: "range",
                    minDate: new Date(),
                    altInput: true,
                    dateFormat: "Y-m-d",
                    altFormat: "d.m.Y",
                    inline: true,
                    disable: out,
                    onMonthChange: function (selectedDates, dateStr, instance) {
                        const newMonth = instance.currentMonth;
                        const newYear = instance.currentYear;
                        syncCalendars(newMonth, newYear, instance);
                    }
                });
                calendarInstances.push(calendarInstance);
            })
            .catch(err => { throw err });
    }

    const syncCalendars = (newMonth, newYear, sourceInstance) => {
        calendarInstances.forEach(instance => {
            if (instance !== sourceInstance) {
                try {
                    const newDate = new Date(newYear, newMonth, 1);
                    instance.jumpToDate(newDate);
                } catch (error) {
                    console.error('Error syncing calendar month:', error);
                }
            }
        });
    };
};


// https://github.com/jlmakes/scrollreveal

const scrollTevealInit = () => {
    ScrollReveal.debug = true;

    let animHeader = document.querySelector('[data-anim-header]')
    let animItemsX = document.querySelectorAll('[data-anim-x]')
    let animItemsXX = document.querySelectorAll('[data-anim-xx]')
    let animItemsXXX = document.querySelectorAll('[data-anim-xxx]')
    let animItemsXXXX = document.querySelectorAll('[data-anim-xxxx]')

    ScrollReveal().reveal(animHeader, {
        duration: 1000,
        delay: 0,
        distance: '-200px',
    })

    ScrollReveal().reveal(animItemsX, {
        duration: 1000,
        delay: 400,
        distance: '100px',
    })

    ScrollReveal().reveal(animItemsXX, {
        duration: 1000,
        delay: 300,
        distance: '100px',
    })

    ScrollReveal().reveal(animItemsXXX, {
        duration: 1000,
        delay: 200,
        distance: '100px',
    })

    ScrollReveal().reveal(animItemsXXXX, {
        duration: 1000,
        delay: 100,
        distance: '100px',
    })

};

const checkout = () => {
    const trigger = document.querySelector('[data-invoice-show]')
    const invoiceInputs = document.querySelector('[data-invoice-select]')

    if(!trigger && !invoiceInputs) return

    trigger.addEventListener('change', (e) => {
        const checkbox = e.target

        if(checkbox.checked) {
            invoiceInputs.setAttribute('data-invoice-select', 'true')
        } else {
            invoiceInputs.setAttribute('data-invoice-select', 'false')
        }

    });

    const trigger2 = document.querySelector('[data-voucher-show]')
    const voucherInput = document.querySelector('[data-voucher-select]')

    if(!trigger2 && !voucherInput) return

    trigger2.addEventListener('change', (e) => {
        const checkbox = e.target

        if(checkbox.checked) {
            voucherInput.setAttribute('data-voucher-select', 'true')
        } else {
            voucherInput.setAttribute('data-voucher-select', 'false')
        }

    });
}

const slickSliderIni = () => {
    if ($('.banner__item').length > 1) {
        $("[data-slider-banner]").not(".slick-initialized")
        .slick({
            dots: true,
            infinite: true,
            autoplay: true,
            arrows: true,
            speed: 800,
            slidesToShow: 1,
            slidesToScroll: 1,
        });
    }
};
